import { useState, useEffect } from "react";
import { useUsers } from "../context/UserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import { supabase } from "../client/client";
import Loading from "./Loading";
import Layout from "./Layout";

const ProductCardArea = () => {
  const [loading, setLoading] = useState(true);
  const [processProducts, setProcessProducts] = useState([]);
  const { getSessionAuth } = useLogin();
  const { area } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        const dataUser = await getUser(session.id);
        await fetchUserProducts(dataUser.name, dataUser.role);
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };

    
    sessionAuth();
    // getUser();
    // fetchUserProducts();
  }, []);

  const getUser = async (id) => { 
    try {
      if (!id) {
        console.error('ID is undefined'); 
        return;
      }
      const { error, data } = await supabase
        .from("users")
        .select()
        .eq(`id`, id)
        .single();
      if (error) {
        console.error("Error fetching user:", error.message);
        return;
      }
      // setUserData(data);
      return data
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }; 

  // const fetchUserProducts = async (name, role) => {
  //   if (!name) {
  //     console.error("User data is null");
  //     return;
  //   }
  //   try {
  //     console.log("HOLAA",name);
  //     const { data, error } = await supabase
  //       .from("equipos")
  //       .select(`*`)  
  //       .eq(`attendant`, name);

  //     if (error) {
  //       console.error("Error fetching data:", error.message, error.details);
  //       return;
  //     }
  //     setProcessProducts(data);
  //   } catch (error) {
  //     console.error("Unexpected error:", error);
  //   }
  // }

  const fetchUserProducts = async (name, role) => {
    if (!name || !role) {
      console.error("User data is null");
      return;
    }
  
    try {
      let query;
  
      if (role === "admin") {
        // Consulta para usuarios con rol "admin"
        query = supabase.from("equipos").select("*").eq("area", area);
      } else if (role === "client") {
        // Consulta para usuarios con rol "client"
        query = supabase.from("equipos").select("*").eq("attendant", name);
      } else {
        console.error("Invalid role:", role);
        return;
      }
  
      const { data, error } = await query;
  
      if (error) {
        console.error("Error fetching data:", error.message, error.details);
        return;
      }
  
      setProcessProducts(data);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return ( 
    <Layout>
      {loading && <Loading />}
      <div className="grid md:grid-cols-5 grid-cols-3 gap-8">
        {processProducts.map((product) => (
          <Link
            to={`/informacion/${product.id}`}
            key={product.id}
            className="flex flex-col gap-2 border border-zinc-300 p-5 hover:bg-zinc-100 transition-colors duration-200"
          >
            <img
              src={(product && (product.image1 !== null && product.image1 !== "")) ? product.image1 : "producto.jpeg"}
              alt={`${product.title}`}
              className="w-full aspect-square"
            />
            <h3 className="text-[#4691A6] font-bold text-lg text-wrap">
              {product.title}
            </h3>
            <h4 className="text-black font-semibold text-xs">{product.area}</h4>
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export default ProductCardArea;
