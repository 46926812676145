import { supabase } from "../client/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import ProductCard from "./ProductCard";
import Layout from "./Layout";

function PerfilPage() {
  const [loading, setLoading] = useState(true);
  const [processArea, setProcessArea] = useState([]);
  const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await getUser(session.id);
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  useEffect(() => {
    if (userData !== null) fetchProcess();
  }, [userData]);

  const getUser = async (id) => {
    const { error, data } = await supabase
      .from("users")
      .select()
      .eq(`id`, id)
      .single();
    if (error) throw error.message;
    setUserData(data);
  };

  async function fetchProcess() {
    try {
      if (!userData) return;
      const { data, error } = await supabase
        .from("area_users")
        .select(`id_area, areas(name)`)
        .eq("id_user", userData.id);

      if (error) {
        console.error("Error fetching data:", error.message, error.details);
        return;
      }

      if (data.length === 0) {
        console.warn("No areas found for the user with ID:", userData.id);
        return;
      }

      const areaNames = data.map((item) => item.areas.name);
      setProcessArea(areaNames);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }

  useEffect(() => {
    fetchProcess();
  }, []);

  return (
    <>
      <Layout className="flex">
        <div className="mb-10">
          <h1 className="text-[#4691A6] font-bold text-4xl">
            {userData && userData.name}
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <div style={{ display: "flex" }}>
            <h3 className="text-[#4691A6] font-bold flex mr-2">EMAIL:</h3>
            <h3>{userData && userData.email}</h3>
          </div>

          <div style={{ display: "flex" }}>
            <h3 className="text-[#4691A6] font-bold flex mr-2">CARGO:</h3>
            {/* <h3>{processArea}</h3> */}
            <h3>{userData && userData.charge}</h3>
          </div>
        </div>

        {/* <h3 className="text-[#4691A6] font-bold flex mt-20">
          Productos a su cargo:
        </h3>
        {<ProductCard />} */}
      </Layout>
    </>
  );
}

export default PerfilPage;
