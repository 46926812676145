import { useEffect, useState } from 'react';
import { supabase } from '../client/client';
import './EquipmentForm.css';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const EquipmentForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        active_type: '',
        image1: '',
        image2: '',
        area: '',
        brand: '',
        slug: '',
        model: '',
        serial: '',
        measures: '',
        color: '',
        state: '',
        tags: [],
        title: '',
        condition_use: '',
        quantity: 0,
        attendant: '',
        detail: '',
        date_maintenance: null,
        observations: '',
        verification: '',
        date_next_maintenance: null,
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const areaOptions = [
        'TECNOLOGIA DE INFORMACION',
        'CONTABILIDAD',
        'COMERCIAL',
        'CAJA',
        'PROTESTOS',
        'ORIGEN',
        'SECRETARIA',
        'SALON VITOR',
        'SALON CERVESUR',
        'LEGAL',
        'DIRECTORIO 3',
        'SALON 1',
        'SALON 2',
        'SALON 3',
        'AUDITORIO',
        'COCINA',
        'BAÑO PERSONAL',
        'BAÑO SALON CERVESUR',
        'BAÑO AUDITORIO',
        'HALL PRIMERA CASONA',
        'HALL SEGUNDA CASONA',
        'MENSAJERIA',
        'GERENCIA',
        'SUBGERENCIA',
        'ARBITRAJE',
    ];

    const attendantOptions = [
        'FABIOLA LÓPEZ CHIRINOS',
        'KATHERINE SUEROS',
        'RUTH VARGAS',
        'ALEJANDRA ALVARADO',
        'GONZALO ZAVALAGA',
        'TAMARA NUÑEZ',
        'DANIELA BENAVENTE',
        'ANTONIO ESCALANTE',
        'JOCELYN CARI',
        'GERALDINE MARQUÉZ',
        'MÓNICA TORRES',
        'GUSTAVO CHIRINOS',
        'BROSWI GALVEZ',
        'SALOMÉ GALDOS',
        'JUAN JAVIER',
        'LUCIANO YNCA',
        'LUDOVINA VILLANUEVA',
        'JIMENA HURTADO',
        'MILUSKA MANRIQUE',
        'JUAN JOSE TICONA',
        'DIEGO VALDIVIA',
        'GIORDANO ARIAS',
        'SHILA MENDOZA GAMERO',
    ];

    useEffect(() => {
        const fetchProductData = async () => {
            if (id) {
                try {
                    const { data, error } = await supabase.from('equipos').select('*').eq('id', id).single();

                    if (error) {
                        console.error('Error fetching product data:', error);
                    } else {
                        setFormData(data);
                    }
                } catch (error) {
                    console.error('Error fetching product data:', error.message);
                }
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                }));
            }
        };

        fetchProductData();
    }, [id]);



    const stateOptions = ['NUEVO', 'OBSOLETO', 'EN USO'];
    const yesNoOptions = ['SI', 'NO'];

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     const formattedValue = name !== 'slug' ? value.toUpperCase() : value;

    //     if (name === 'title' || name === 'serial') {
    //         const formattedTitle = formData.title.toLowerCase().replace(/\s+/g, '_');
    //         const formattedSerial = formData.serial.toLowerCase().replace(/\s+/g, '_');
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             [name]: formattedValue,
    //             slug: `${formattedTitle}_${formattedSerial}`,
    //         }));
    //     } else {
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             [name]: formattedValue,
    //         }));
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;

        let formattedValue = value;

        if (name === 'date_next_maintenance' || name === 'date_maintenance') {
            const parts = value.split('/');
            if (parts.length === 3) {
                formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
        }

        if (name !== 'slug') {
            formattedValue = formattedValue.toUpperCase();
        }

        if (name === 'title' || name === 'serial') {
            const formattedTitle = formData.title.toLowerCase().replace(/\s+/g, '_');
            const formattedSerial = formData.serial.toLowerCase().replace(/\s+/g, '_');
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
                slug: `${formattedTitle}_${formattedSerial}`,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
        }
    };


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!e.target.image1.files || e.target.image1.files.length === 0) {
    //         alert("Por favor, selecciona una imagen.");
    //         return;
    //     }

    //     const now = new Date().toISOString();

    //     const imageFile = e.target.image1.files[0];
    //     const imageName = uuidv4() + ".jpeg";

    //     try {
    //         // Subir la imagen a Supabase Storage
    //         const { data: uploadData, error: uploadError } = await supabase.storage
    //             .from('inventario_ccia')
    //             .upload(imageName, imageFile);

    //         if (uploadError) {
    //             console.error(uploadError);
    //             alert("Error uploading file to Supabase");
    //             return;
    //         }

    //         console.log("Image uploaded successfully");

    //         const imageUrl = supabase.storage.from('inventario_ccia').getPublicUrl(imageName);
    //         const oficialImage = imageUrl.data.publicUrl;

    //         const updatedData = {
    //             ...formData,
    //             image1: oficialImage,
    //         };

    //         const { data: updateData, error: updateError } = await supabase.from('equipos').update({
    //             updated_at: now,
    //             ...updatedData,
    //         }).eq('id', id);

    //         if (updateError) {
    //             console.error('Error updating image1 field:', updateError);
    //             alert("Error updating image1 field");
    //         } else {
    //             console.log('Image1 field updated successfully:', updateData);
    //             setFormData(updatedData);
    //             setShowSuccessDialog(true);
    //         }
    //     } catch (error) {
    //         console.error('Error handling image change:', error.message);
    //         alert("Error handling image change");
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const now = new Date().toISOString();

        let updatedData = { ...formData };



        if (e.target.image1.files && e.target.image1.files.length > 0) {
            const imageFile1 = e.target.image1.files[0];
            const imageName1 = uuidv4() + ".jpeg";
            const { data: uploadData1, error: uploadError1 } = await supabase.storage
                .from('inventario_ccia')
                .upload(imageName1, imageFile1);

            if (uploadError1) {
                console.error(uploadError1);
                alert("Error uploading file to Supabase");
                return;
            }

            console.log("Image uploaded successfully");

            const imageUrl1 = supabase.storage.from('inventario_ccia').getPublicUrl(imageName1);
            const oficialImage1 = imageUrl1.data.publicUrl;

            updatedData = { ...updatedData, image1: oficialImage1 };
        }

        if (e.target.image2.files && e.target.image2.files.length > 0) {
            const imageFile2 = e.target.image2.files[0];
            const imageName2 = uuidv4() + ".jpeg";
            const { data: uploadData2, error: uploadError2 } = await supabase.storage
                .from('inventario_ccia')
                .upload(imageName2, imageFile2);

            if (uploadError2) {
                console.error(uploadError2);
                alert("Error uploading file to Supabase");
                return;
            }

            console.log("Image uploaded successfully");

            const imageUrl2 = supabase.storage.from('inventario_ccia').getPublicUrl(imageName2);
            const oficialImage2 = imageUrl2.data.publicUrl;

            updatedData = { ...updatedData, image2: oficialImage2 };
        }

        if (id) {

            const { data: updateData, error: updateError } = await supabase.from('equipos').update({
                updated_at: now,
                ...updatedData,
            }).eq('id', id);
            if (updateError) {
                alert("Error updating");
            } else {
                setFormData(updatedData);
                setShowSuccessDialog(true);
            }
        }
        else {
        
            const { data: insertData, error: insertError } = await supabase.from('equipos').insert(
                {
                    ...updatedData,
                }
            );
            if (insertError) {
                console.log("Error inserting data:", insertError);
                alert("Error inserting data");
            } else {
                console.log("Data inserted successfully:");
                setFormData(updatedData);
                setShowSuccessDialog(true);
            }
        }


    };


    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/equipos");
    };

    const sortedAttendantOptions = attendantOptions.slice().sort();
    const sortedAreaOptions = areaOptions.slice().sort();
    const sortedStateOptions = stateOptions.slice().sort();

    return (
        <Layout>
            <form className="equipment-form" onSubmit={handleSubmit}>
                <h1 className='text-3xl font-bold text-[#4691A6] flex justify-center pb-10'>HARDWARE</h1>
                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Tipo de Activo:
                            <input
                                type="text"
                                name="active_type"
                                value={formData.active_type}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Nombre del Equipo:
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Cantidad:
                            <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Marca:
                            <input
                                type="text"
                                name="brand"
                                value={formData.brand}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Modelo:
                            <input
                                type="text"
                                name="model"
                                value={formData.model}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Número de Serie:
                            <input
                                type="text"
                                name="serial"
                                value={formData.serial}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Medidas:
                            <input
                                type="text"
                                name="measures"
                                value={formData.measures}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Color:
                            <input
                                type="text"
                                name="color"
                                value={formData.color}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Estado:
                            <div className="styled-select">
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedStateOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Condición de Uso:
                            <div className="styled-select">
                                <select
                                    name="condition_use"
                                    value={formData.condition_use}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {yesNoOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Encargado del Equipo:
                            <div className="styled-select">
                                <select
                                    name="attendant"
                                    value={formData.attendant}
                                    onChange={handleChange}
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedAttendantOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Área:
                            <div className="styled-select">
                                <select
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedAreaOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>


                </div>

                <div className="form-row pb-10">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Slug:
                            <input
                                type="text"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                                readOnly
                            />
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Imagen 1:
                            <input
                                type="file"
                                name="image1"
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Imagen 2:
                            <input
                                type="file"
                                name="image2"
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                
                <div className="form-row">
                    <button className="bg-[#356674] hover:bg-[#4691A6] transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full" type="submit">Guardar</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-[#356674] hover:bg-[#4691A6] transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default EquipmentForm;