// import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from "react-router-dom";
// import { supabase } from "../../client/client";

// const FetchSoftwareDetail = () => {
//     const [data, setData] = useState([]);
//     const { slug } = useParams();

//     console.log("slug",slug);

//     const fetchData = async () => {
//         console.log("slug2",slug);
//         try {
//             const { data: equipoData, error: equipoError } = await supabase
//                 .from('equipos')
//                 .select('id')
//                 .eq('slug', slug)

//             if (equipoError) {
//                 throw equipoError;
//             }

//             console.log("data",equipoData);

//         } catch (error) {
//             console.error("Error fetching data:", error.message);
//         }
//     };

//     // Obtener datos de la licencia usando el id del equipo obtenido del slug
//             // const { id } = equipoData; // Asegúrate de que 'id' sea el campo correcto
//             // console.log("id",id);
//             // const { data: licenseData, error: licenseError } = await supabase
//             //     .from('licencias')
//             //     .select('*')
//             //     .eq("id_equipo", id)
//             //     .single();

//             // if (licenseError) {
//             //     throw licenseError;
//             // }

//             // setData(licenseData);
//             // console.log("info", licenseData);

//     useEffect(() => {
//         fetchData();
//     }, [slug]); // Agregar slug a la lista de dependencias para que se ejecute nuevamente cuando cambie

//     return (
//         <div>FetchSoftwareDetail</div>
//     );
// }

// export default FetchSoftwareDetail;


// import React, { useEffect, useState } from 'react';
// import { useParams } from "react-router-dom";
// import { supabase } from "../../client/client";

// const FetchSoftwareDetail = () => {
//     const [equipoData, setEquipoData] = useState(null);
//     const [licenseData, setLicenseData] = useState(null);
//     const { slug } = useParams();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const { data: equipo, error: equipoError } = await supabase
//                     .from('equipos')
//                     .select('id')
//                     .eq("id", slug)
//                     .single();

//                 if (equipoError) {
//                     throw equipoError;
//                 }

//                 setEquipoData(equipo.id);
//                 console.log("Equipo encontrado:", equipoData);
//             } catch (error) {
//                 console.error("Error al obtener datos del equipo:", error.message);
//             }
//         };
//         fetchData();
//         fetchSoftwareData(slug);
//     }, [slug]);

//     const fetchSoftwareData = async () => {
//         console.log(slug);
//         try {
//             const { data, error: licenseError } = await supabase
//                 .from('licencias')
//                 .select('description,license')
//                 .eq("id_equipo", slug);

//             if (licenseError) {
//                 throw licenseError;
//             }

//             setLicenseData(data);
//             console.log("info", licenseData);
//         }
//         catch (error) {
//             console.error("Error al obtener datos del equipo:", error.message);
//         }
//     }

//     return (
//         <div>
//             {licenseData.map((license, index) => (
//                 <div key={index}>
//                     <h5 className="text-sm font-bold">{license.description}:</h5>
//                     <p className="text-sm font-medium">{license.license}</p>
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default FetchSoftwareDetail;
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { supabase } from "../../client/client";

const FetchSoftwareDetail = () => {
    const [equipoData, setEquipoData] = useState(null);
    const [licenseData, setLicenseData] = useState([]);
    const { slug } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: equipo, error: equipoError } = await supabase
                    .from('equipos')
                    .select('id')
                    .eq("id", slug)
                    .single();

                if (equipoError) {
                    throw equipoError;
                }

                setEquipoData(equipo.id);
            } catch (error) {
                console.error("Error al obtener datos del equipo:", error.message);
            }
        };
        fetchData();
        fetchSoftwareData(slug);
    }, [slug]);

    const fetchSoftwareData = async () => {
        try {
            const { data, error: licenseError } = await supabase
                .from('licencias')
                .select('*')
                .eq("id_equipo", slug);

            if (licenseError) {
                throw licenseError;
            }

            setLicenseData(data);
            console.log(data);
        }
        catch (error) {
            console.error("Error al obtener datos del equipo:", error.message);
        }
    }

    return (
        <div>
            {licenseData.length > 0 ? (
                licenseData.map((license, index) => (
                    <div key={index} className='pb-5'>
                        <div className='flex gap-3'>
                            <p className="text-sm font-bold">Descripción:</p>
                            <p className="text-sm font-medium">{license.description}</p>
                        </div>
                        <div className='flex gap-3'>
                            <p className="text-sm font-bold">Licencia:</p>
                            <p className="text-sm font-medium">{license.license}</p>
                        </div>
                        <div className='flex gap-3'>
                            <p className="text-sm font-bold">Duración de la licencia:</p>
                            <p className="text-sm font-medium">{license.license_duration}</p>
                        </div>
                        <div className='flex gap-3'>
                            <p className="text-sm font-bold">Fecha de expiración:</p>
                            <p className="text-sm font-medium">{license.date_expiry}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>Este equipo no cuenta con licencias.</p>
            )}
        </div>
    );
}

export default FetchSoftwareDetail;
