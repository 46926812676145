import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../client/client";
import { useLogin } from "../context/LoginContext";
import DataGrid from "react-data-grid";
import * as XLSX from "xlsx";
import DownloadIcon from "./DownloadIcon";
import CreateIcon from "./CreateIcon";
import Layout from "./Layout";
import Loading from "./Loading";

const columns = [
  {
    field: "name",
    name: "Nombre",
    key: "name",
    width: 400,
    renderCell: ({ row }) => {
      return <div>{row.name}</div>;
    },
  },
  { field: "email", name: "Email", width: 300, key: "email" },
  { field: "role", name: "Rol", width: 300, key: "role" },
  { field: "charge", name: "Cargo", width: 230, key: "charge" },
];

const Usuarios = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const { userData, getUser } = useUsers();
  //   const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        // await getUser(session.id);
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  //   const getUser = async (id) => {
  //     const { error, data } = await supabase
  //       .from("users")
  //       .select()
  //       .eq(`id`, id)
  //       .single();
  //     if (error) throw error.message;
  //     setUserData(data);
  //   };

  // console.log(user)

  // useEffect(() => {
  //     if (user) {
  //         navigate("/usuarios", { replace: true });
  //         getUser(user.id);
  //     }
  //     else {
  //         navigate("/login");
  //     }
  // }, [user])

  const handleDownloadExcel = async () => {
    try {
      const filteredUsersData = data;
  
      const sheet = XLSX.utils.json_to_sheet(filteredUsersData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, "Usuarios");
  
      XLSX.writeFile(workbook, "usuarios.xlsx");
    } catch (error) {
      console.error("Error handling download:", error.message);
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: usersData, error: usersError } = await supabase.from("users").select("*");
        if (usersError) {
          console.error("Error fetching users data:", usersError);
          return;
        }

        const { data: areaUsersData, error: areaUsersError } = await supabase
          .from("area_users")
          .select("id_user")
          .eq("id_area", 25); 
        
        if (areaUsersError) {
          console.error("Error fetching area_users data:", areaUsersError);
          return;
        }
        const filteredUsersData = usersData.filter(user => {
          const isUserInArea = areaUsersData.some(areaUser => areaUser.id_user === user.id);
          console.log(`User ${user.id} is in area: ${isUserInArea}`);
          return isUserInArea;
        });
  
        setData(filteredUsersData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
  
    fetchData();
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex items-center justify-between mb-10">
        <h1 className="text-[#4691A6] font-semibold text-3xl">
          LISTADO DE USUARIOS
        </h1>
        <div className="flex items-center gap-2">
          <Link to="/formulario-usuarios">
            <button className="bg-[#356674] hover:bg-[#4691A6] transition-colors duration-300 text-sm cursor-pointer h-8 flex gap-1 items-center px-3 py-1 text-white">
              <CreateIcon className="w-4" />
              Crear usuario
            </button>
          </Link>
          <button
            onClick={handleDownloadExcel}
            className="bg-[#356674] hover:bg-[#4691A6] transition-colors duration-300 text-sm cursor-pointer h-8 flex gap-1 items-center px-3 py-1 text-white"
          >
            <DownloadIcon className="w-5" />
            Descargar Excel
          </button>
        </div>
      </div>
      <DataGrid rows={data} columns={columns} className="rdg-light" />
    </Layout>
  );
};

export default Usuarios;
