import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { supabase } from "../../client/client";

const FetchMaintenanceDetail = () => {
    const [equipoData, setEquipoData] = useState(null);
    const [maintenanceData, setMaintenanceData] = useState([]);
    const { slug } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: equipo, error: equipoError } = await supabase
                    .from('equipos')
                    .select('id')
                    .eq("id", slug)
                    .single();

                if (equipoError) {
                    throw equipoError;
                }

                setEquipoData(equipo.id);
            } catch (error) {
                console.error("Error al obtener datos del equipo:", error.message);
            }
        };
        fetchData();
        fetchSoftwareData(slug);
    }, [slug]);

    const fetchSoftwareData = async () => {
        console.log(slug);
        try {
            const { data, error: maintenanceError } = await supabase
                .from('maintenance')
                .select('*')
                .eq("id_equipo", slug)
                .order('date_maintenance', { ascending: false })

            if (maintenanceError) {
                throw maintenanceError;
            }

            const latestMaintenance = data.length > 0 ? data[0] : null;

            setMaintenanceData(latestMaintenance);
        }
        catch (error) {
            console.error("Error al obtener datos del equipo:", error.message);
        }
    };

    return (
        <div>
            {maintenanceData && Object.keys(maintenanceData).length > 0 ? (
                <div>
                    <div className='flex gap-5'>
                        <h5 className='text-sm font-bold'>Fecha de último mantenimiento:</h5>
                        <h5 className='text-sm'>{maintenanceData.date_maintenance}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Detalle:</h5>
                        <h5 className='text-sm'>{maintenanceData.detail}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Observaciones:</h5>
                        <h5 className='text-sm'>{maintenanceData.observations}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Fecha próximo mantenimiento:</h5>
                        <h5 className='text-sm'>{maintenanceData.date_next_maintenance}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Correctivo:</h5>
                        <h5 className='text-sm'>{maintenanceData.correctivo}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Fecha de correctivo:</h5>
                        <h5 className='text-sm'>{maintenanceData.date_correctivo}</h5>
                    </div>
                    <div className='flex gap-5 mt-4'>
                        <h5 className='text-sm font-bold'>Frecuencia de mantenimiento:</h5>
                        <h5 className='text-sm'>{maintenanceData.frecuency_maintenance}</h5>
                    </div>
                </div>
            ) : (
                <p>Este equipo aún no cuenta con registros de mantenimiento.</p>
            )}
        </div>
    );
}

export default FetchMaintenanceDetail;
